import { defineStore } from 'pinia'
import { ref } from 'vue'

const initialTag = [
  {
    fullPath: '/dashboard',
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      title: '首页'
    }
  }
]
export const useTagsStore = defineStore('tags', () => {
  const visitedTags = ref(initialTag)
  const cachedTags = ref(initialTag.map(v => v.name))
  function addVisitedTags(route) {
    if (visitedTags.value.some(v => v.fullPath === route.fullPath)) return
    visitedTags.value.push(
      Object.assign({}, route)
    )
  }
  function deleteVisitedTags(route) {
    if (visitedTags.value.indexOf(route) !== -1) {
      visitedTags.value.splice(visitedTags.value.indexOf(route), 1)
    }
  }
  function clearVisitedTags() {
    visitedTags.value = initialTag
  }

  function addCachedTags(route) {
    if (cachedTags.value.includes(route.name)) return
    cachedTags.value.push(route.name)
  }
  function deleteCachedTags(route) {
    cachedTags.value.splice(cachedTags.value.indexOf(route.name), 1)
  }
  function clearCachedTags() {
    cachedTags.value = initialTag.map(v => v.name)
  }
  return {
    visitedTags,
    addVisitedTags,
    deleteVisitedTags,
    clearVisitedTags,
    cachedTags,
    addCachedTags,
    deleteCachedTags,
    clearCachedTags
  }
})