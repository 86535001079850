<template>
  <div class="contentBox px-4 w-full">
    <div class="content flex items-center">
      <div class="flex-1 flex">
        <svg-icon class="cursor-pointer" v-show="!menuCollapse" name="collapse" color="#515f8f" @click="handleSetMenuCollapse"></svg-icon>
        <svg-icon class="cursor-pointer" v-show="menuCollapse" name="expand" color="#515f8f" @click="handleSetMenuCollapse"></svg-icon>
      </div>
      <el-dropdown @command="handleCommand">
        <div class="flex items-center">
          <el-avatar class="mr-2" :size="30" :src="circleUrl" />
          {{ userData ? userData.name : '' }}
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="settings">个人中心</el-dropdown-item>
            <el-dropdown-item command="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import { logout } from '@/api'
import { computed, ref } from 'vue'
import avatar from '@/assets/avatar1.png'
import { useGlobalStore } from '@/store'
import SvgIcon from '@/components/svgIcon/index.vue'

const router = useRouter()
const circleUrl = ref(avatar)
const store = useGlobalStore()
const userData = computed(() => store.userData)
const menuCollapse = computed(() => store.menuCollapse)

const handleSetMenuCollapse = () => {
  store.setMenuCollapse(!menuCollapse.value)
}
const handleLogout = () => {
  ElMessageBox.confirm('确定退出吗？', '提示', {
    type: 'warning'
  }).then(() => {
    logout({
      token: localStorage.getItem('token')
    }).then(() => {
      router.push('/login')
      localStorage.removeItem('token')
    })
  }).catch(() => {})
}

const handleCommand = (command) => {
  console.log(command)
  if (command === 'logout') {
    handleLogout()
  } else if (command === 'settings') {
    router.push('/user/account')
  }
}
</script>

<style lang="scss" scoped>
.contentBox {
  transform: translateZ(0);
  box-shadow: 0 2px 8px var(--itsop-background);
  z-index: 250;
  position: absolute;
  top: 0;
}
.content {
  width: var(--itsop-content-width);
  margin: 0 auto;
  height: var(--itsop-header-height);
  line-height: var(--itsop-header-height);
  .logo {
    width: 200px;
  }
  a {
    color: var(--itsop-font-2);
  }
  .router-link-active {
    font-weight: bolder;
    color: var(--el-color-primary);
  }
}
</style>