<template>
  <div>
    <div class="app-logo py-3">
      <img class="mr-1" src="/logo.png" alt="" width="40">
      <span class="app-logo--text"  :class="{ collapseHidden: collapse }">Ins Cloud</span>
    </div>
    <el-menu
        :default-active="currentRoute"
        @select="handleSelect"
        background-color="#0B132B"
        active-text-color="#fff"
        :collapse="collapse"
    >
      <el-menu-item v-show="!item.meta.isHidden" v-for="item in menu" :index="item.path" :key="item.path" :route="item.path">
        <el-icon>
          <svg-icon :name="item.meta.icon" />
        </el-icon>
        <template #title>
          <span>{{ item.meta.title }}</span>
        </template>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { routes } from '@/router'
import router from '@/router'
import { useRoute } from 'vue-router'
import { useGlobalStore } from '@/store'
import SvgIcon from '@/components/svgIcon'

const cRouter = useRoute()
const menu = ref(routes.filter(v => !v.meta || !v.meta.isHidden)[0].children)
const currentRoute = computed(() => cRouter.path)

const globalStore = useGlobalStore()
const collapse = computed(() => globalStore.menuCollapse)

const handleSelect = (key) => {
  router.push({ path: key })
}
</script>

<style lang="scss">
.app-logo {
  color: #ffffff;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding-left: 18px;
  font-size: 16px;
  box-sizing: border-box;
  transition: all 0.3s;
  &--text {
    transition: opacity .5s, width .5s ease-in-out;
    //-webkit-transition: opacity .5s, width 0.5s ease-in-out;
    opacity: 1;
    width: 136px;
    white-space: nowrap;
    overflow: hidden;
  }
  &--text.collapseHidden {
    opacity: 0;
    //display: none;
    width: 0;
  }
}
.el-menu {
  border: none !important;
  z-index: 111;
}
</style>